import {
  DropDown,
  IconButton,
  PersonOffIcon,
  AvatarBox,
} from '@guider-global/ui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem as MuiMenuItem } from '@mui/material';

export type RelationshipManageDropDownViewProps = {
  status: 'active' | 'concluded';
  onSelectConclude: () => void;
};

export function RelationshipManageDropDownView({
  status,
  onSelectConclude,
}: RelationshipManageDropDownViewProps) {
  return (
    <DropDown
      anchor={
        <IconButton
          variant="regular"
          disabled={status === 'concluded'}
          icon={<MoreHorizIcon />}
        />
      }
    >
      <MuiMenuItem onClick={onSelectConclude} disabled={status === 'concluded'}>
        <AvatarBox
          icon={<PersonOffIcon size="small" />}
          size="small"
          heading="Conclude Relationship"
          subtitles={[
            'This will conclude the relationship meaning that both users wil no longer be able to message or schedule a session.',
          ]}
        />
      </MuiMenuItem>
    </DropDown>
  );
}
