import { useAuth } from '@guider-global/auth-hooks';
import {
  firstLetterUppercase,
  getCountryList,
} from '@guider-global/front-end-utils';
import { useOrganization } from '@guider-global/sanity-hooks';
import {
  IChartsProgramActiveRelationship,
  IChartsProgramGuideProfile,
  IChartsProgramPostRelationshipSurveys,
  IChartsProgramPostSessionSurveys,
  IChartsProgramTraineeProfile,
  IChartsSkillsOffered,
  IChartsSkillsSought,
  IFilterModel,
  Program,
} from '@guider-global/shared-types';
import {
  Button,
  Chip,
  CustomAutocompleteFilter,
  IMultiSelectChipFilterOption,
  ITableRefs,
  KeyStatCard,
  PieChartCard,
  Stack,
  Text,
  getCustomGridDateFilterOperator,
} from '@guider-global/ui';
import Refresh from '@mui/icons-material/Refresh';
import { Grid as MuiGrid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultizedPieValueType } from '@mui/x-charts';
import { GridCellParams, GridColDef, GridFilterItem } from '@mui/x-data-grid';
import {
  MetricsProgramIndividualFilterContainer,
  SkillsMetricsTableContainer,
} from 'containers';
import { MongoMetricsContainer } from 'containers/MongoMetricsContrainer';
import { useAppContext, useProgramIndividualTableContext } from 'context';
import { format } from 'date-fns';
import {
  getCountryColumn,
  getGoalsColumns,
  getSanityProfileFieldColumns,
  getSanityProgramFieldColumns,
  getSanitySurveyFieldColumns,
} from 'functions';
import {
  useAdminChartsGoals,
  useAdminChartsProgramActiveRelationships,
  useAdminChartsProgramConcludedRelationships,
  useAdminChartsProgramGuideProfiles,
  useAdminChartsProgramTraineeProfiles,
  useAdminChartsSessions,
  useAdminChartsSkillsOffered,
  useAdminChartsSkillsSought,
  useAdminKPIsOrganization,
  useAdminKPIsProgram,
  useSnackbar,
} from 'hooks';
import { useAdminChartsProgramPostRelationshipSurveys } from 'hooks/admin/useAdminChartsProgramPostRelationshipSurveys';
import { useAdminChartsProgramPostSessionSurveys } from 'hooks/admin/useAdminChartsProgramPostSessionSurveys';
import { getUpdatedAt } from 'pages/ProgramMetricsPage';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageView } from 'views';

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 75, 100];

export interface IndividualProgramMetricsViewProps {
  program: Program;
}

export function IndividualProgramMetricsView({
  program,
}: Readonly<IndividualProgramMetricsViewProps>) {
  const { organizationSlug } = useAppContext();
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const isLaptop = useMediaQuery(breakpoints.down('lg'));
  const navigate = useNavigate();

  const { setError } = useSnackbar();

  const programSlug = program.metadata.id.current;
  const programOrganizationSlug =
    program.metadata.organization?.basic_info.subdomain.current ?? 'guider';

  const {
    organization: sanityOrganization,
    isLoadingOrganization,
    isValidatingOrganization,
  } = useOrganization({
    organizationSlug: organizationSlug ?? programOrganizationSlug,
    showHiddenProfileData: true,
  });

  const programTypeName =
    program?.program_details?.program_type?.program_type_text.common?.verb;
  const programName = program?.metadata.program_name;
  const sanityProgramFields =
    program?.registration?.registration_questions ?? [];
  const programType = program?.program_details?.program_type;
  const guideOrganizationLabel =
    programType?.program_type_text.common?.guide?.singular;
  const traineeOrganizationLabel =
    programType?.program_type_text.common?.trainee?.singular;
  const guideOrganizationLabelPluralized =
    programType?.program_type_text.common?.guide?.pluralized;
  const traineeOrganizationLabelPluralized =
    programType?.program_type_text.common?.trainee?.pluralized;
  const sanityOrganizationFields = sanityOrganization?.profile_fields ?? [];
  const sanityOrganizationPersonalDetails =
    sanityOrganization?.personal_details;
  const isSanityOrganizationCountryEnabled =
    sanityOrganizationPersonalDetails?.country.enabled ?? false;
  const sanityPostSessionSurvey =
    program?.surveys?.post_session_survey?.fields ?? [];
  const sanityPostRelationshipSurvey =
    program?.surveys?.post_relationship_survey?.fields ?? [];

  const sanityOrganizationFieldColumns = getSanityProfileFieldColumns<
    IChartsProgramTraineeProfile | IChartsProgramGuideProfile
  >({
    fields: sanityOrganizationFields,
    customFieldId: (id) => `customFields.${id}`,
    getColumnOptions: (id) => ({
      minWidth: 130,
      hideable: true,
      valueGetter: ({ row }) => {
        const fieldSlug: keyof (
          | IChartsProgramTraineeProfile
          | IChartsProgramGuideProfile
        )['customFields'] = id;
        const customFields = row.customFields;

        return customFields[fieldSlug];
      },
    }),
  });

  const {
    models: {
      programGuideModel,
      programTraineeModel,
      programActiveRelationshipsModel,
      programConcludedRelationshipsModel,
      programGoalsModel,
      sessionsModel,
      kpiModel,
      skillsOfferedModel,
      skillsSoughtModel,
      programSurveysPostRelationshipModel,
      programSurveysPostSessionModel,
    },
    updateProgramGuideModel,
    updateProgramTraineeModel,
    updateProgramActiveRelationshipsModel,
    updateProgramConcludedRelationshipsModel,
    updateProgramGoalsModel,
    updateSessionsModel,
    updateProgramSurveysPostRelationshipModel,
    updateProgramSurveysPostSessionModel,
  } = useProgramIndividualTableContext();

  const countryOptions: IMultiSelectChipFilterOption[] = useMemo(
    () =>
      getCountryList({ countryCode: 'GB', languageCode: 'en' }).map(
        ({ key, countryName }) => ({
          id: key,
          label: countryName,
          color: 'gray',
          variant: 'outlined',
        }),
      ),
    [],
  );

  const tableRefs: ITableRefs = {
    guideTable: useRef<HTMLDivElement>(null),
    traineeTable: useRef<HTMLDivElement>(null),
    sessions: useRef<HTMLDivElement>(null),
    activeRelationships: useRef<HTMLDivElement>(null),
    concludedRelationships: useRef<HTMLDivElement>(null),
    programGoalsTable: useRef<HTMLDivElement>(null),
  };

  const {
    isLoading: isLoadingAuth,
    isAuthenticated,
    isAuthorized,
  } = useAuth({});

  const {
    adminKPIsProgram,
    revalidateAdminKPIsProgram,
    isLoadingAdminKPIsProgram,
    isValidatingAdminKPIsProgram,
  } = useAdminKPIsProgram({
    params: {
      programSlug,
      filterModel: kpiModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({ message: error.message, title: 'Failed to get KPIs' });
      },
    },
  });

  const {
    adminChartsProgramGuideProfiles = [],
    isLoadingAdminChartsProgramGuideProfiles,
    isValidatingAdminChartsProgramGuideProfiles,
    revalidateAdminChartsProgramGuideProfiles,
  } = useAdminChartsProgramGuideProfiles({
    params: {
      programSlug,
      reportName: 'program-guide-profiles',
      page: programGuideModel.paginationModel.page + 1,
      sortModel: programGuideModel.sortModel,
      pageLimit: programGuideModel.paginationModel.pageSize,
      filterModel: programGuideModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: `Failed to get ${guideOrganizationLabel} Profiles Chart`,
        });
      },
    },
  });

  const {
    adminChartsProgramTraineeProfiles = [],
    revalidateAdminChartsProgramTraineeProfiles,
    isValidatingAdminChartsProgramTraineeProfiles,
    isLoadingAdminChartsProgramTraineeProfiles,
  } = useAdminChartsProgramTraineeProfiles({
    params: {
      programSlug,
      reportName: 'program-trainee-profiles',
      page: programTraineeModel.paginationModel.page + 1,
      pageLimit: programTraineeModel.paginationModel.pageSize,
      sortModel: programTraineeModel.sortModel,
      filterModel: programTraineeModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: `Failed to get ${traineeOrganizationLabel} Chart`,
        });
      },
    },
  });

  const {
    adminChartsProgramActiveRelationships = [],
    isLoadingAdminChartsProgramActiveRelationships,
    isValidatingAdminChartsProgramActiveRelationships,
    revalidateAdminChartsProgramActiveRelationships,
  } = useAdminChartsProgramActiveRelationships({
    params: {
      programSlug,
      reportName: 'program-active-relationships',
      page: programActiveRelationshipsModel.paginationModel.page + 1,
      pageLimit: programActiveRelationshipsModel.paginationModel.pageSize,
      sortModel: programActiveRelationshipsModel.sortModel,
      filterModel: programActiveRelationshipsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Active Relationships',
        });
      },
    },
  });

  const {
    adminChartsProgramConcludedRelationships = [],
    isLoadingAdminChartsProgramConcludedRelationships,
    isValidatingAdminChartsProgramConcludedRelationships,
    revalidateAdminChartsProgramConcludedRelationships,
  } = useAdminChartsProgramConcludedRelationships({
    params: {
      page: programConcludedRelationshipsModel.paginationModel.page + 1,
      pageLimit: programConcludedRelationshipsModel.paginationModel.pageSize,
      programSlug,
      reportName: 'program-concluded-relationships',
      sortModel: programConcludedRelationshipsModel.sortModel,
      filterModel: programConcludedRelationshipsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Concluded Relationships Chart',
        });
      },
    },
  });

  const {
    adminChartsGoals = [],
    isLoadingAdminChartsGoals,
    isValidatingAdminChartsGoals,
    revalidateAdminChartsGoals,
  } = useAdminChartsGoals({
    params: {
      page: programGoalsModel.paginationModel.page + 1,
      pageLimit: programGoalsModel.paginationModel.pageSize,
      programSlug,
      sortModel: programGoalsModel.sortModel,
      filterModel: programGoalsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Goals Chart',
        });
      },
    },
  });

  const {
    adminChartsSessions = [],
    isLoadingAdminChartsSessions,
    isValidatingAdminChartsSessions,
    revalidateAdminChartsSessions,
  } = useAdminChartsSessions({
    params: {
      page: sessionsModel.paginationModel.page + 1,
      pageLimit: sessionsModel.paginationModel.pageSize,
      programSlug,
      reportName: 'sessions',
      sortModel: sessionsModel.sortModel,
      filterModel: sessionsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Sessions Chart',
        });
      },
    },
  });

  const {
    adminChartsSkillsSought = [],
    isLoadingAdminChartsSkillsSought,
    isValidatingAdminChartsSkillsSought,
    revalidateAdminChartsSkillsSought,
  } = useAdminChartsSkillsSought({
    params: {
      programSlug,
      reportName: 'skills-sought',
      filterModel: skillsSoughtModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Skills Sought Chart',
        });
      },
    },
  });

  const {
    adminChartsSkillsOffered = [],
    isLoadingAdminChartsSkillsOffered,
    isValidatingAdminChartsSkillsOffered,
    revalidateAdminChartsSkillsOffered,
  } = useAdminChartsSkillsOffered({
    params: {
      programSlug,
      reportName: 'skills-offered',
      filterModel: skillsOfferedModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Skills Offered Chart',
        });
      },
    },
  });

  const {
    adminChartsProgramPostRelationshipSurveys = [],
    revalidateAdminChartsProgramPostRelationshipSurveys,
    isLoadingAdminChartsProgramPostRelationshipSurveys,
    isValidatingAdminChartsProgramPostRelationshipSurveys,
  } = useAdminChartsProgramPostRelationshipSurveys({
    params: {
      programSlug,
      reportName: 'program-post-relationship-surveys',
      page: programSurveysPostRelationshipModel.paginationModel.page + 1,
      pageLimit: programSurveysPostRelationshipModel.paginationModel.pageSize,
      sortModel: programSurveysPostRelationshipModel.sortModel,
      filterModel: programSurveysPostRelationshipModel.filterModel,
    },
  });

  const {
    adminChartsProgramPostSessionSurveys = [],
    revalidateAdminChartsProgramPostSessionSurveys,
    isLoadingAdminChartsProgramPostSessionSurveys,
    isValidatingAdminChartsProgramPostSessionSurveys,
  } = useAdminChartsProgramPostSessionSurveys({
    params: {
      programSlug,
      reportName: 'program-post-session-surveys',
      page: programSurveysPostSessionModel.paginationModel.page + 1,
      pageLimit: programSurveysPostSessionModel.paginationModel.pageSize,
      sortModel: programSurveysPostSessionModel.sortModel,
      filterModel: programSurveysPostSessionModel.filterModel,
    },
  });

  const {
    adminKPIsOrganization,
    isLoadingAdminKPIsOrganization,
    isValidatingAdminKPIsOrganization,
    revalidateAdminKPIsOrganization,
  } = useAdminKPIsOrganization({
    options: {
      onError: (error) => {
        setError({ message: error.message, title: 'Failed to get KPIs' });
      },
    },
  });

  const isLoading =
    isLoadingAuth ||
    !isAuthenticated ||
    !isAuthorized ||
    isLoadingOrganization ||
    isLoadingAdminChartsProgramGuideProfiles ||
    isLoadingAdminChartsProgramTraineeProfiles ||
    isLoadingAdminChartsProgramActiveRelationships ||
    isLoadingAdminChartsProgramConcludedRelationships ||
    isLoadingAdminChartsGoals ||
    isLoadingAdminChartsSessions ||
    isLoadingAdminChartsSkillsSought ||
    isLoadingAdminChartsSkillsOffered ||
    isLoadingAdminKPIsProgram ||
    isLoadingAdminChartsProgramPostRelationshipSurveys ||
    isLoadingAdminChartsProgramPostSessionSurveys ||
    isLoadingAdminKPIsOrganization;

  const isValidating =
    isValidatingOrganization ||
    isValidatingAdminChartsProgramGuideProfiles ||
    isValidatingAdminChartsProgramTraineeProfiles ||
    isValidatingAdminChartsProgramActiveRelationships ||
    isValidatingAdminChartsProgramConcludedRelationships ||
    isValidatingAdminChartsGoals ||
    isValidatingAdminChartsSessions ||
    isValidatingAdminChartsSkillsSought ||
    isValidatingAdminChartsSkillsOffered ||
    isValidatingAdminKPIsProgram ||
    isValidatingAdminChartsProgramPostRelationshipSurveys ||
    isValidatingAdminChartsProgramPostSessionSurveys ||
    isValidatingAdminKPIsOrganization;

  function refresh() {
    revalidateAdminKPIsProgram();
    revalidateAdminKPIsOrganization();
    revalidateAdminChartsProgramGuideProfiles();
    revalidateAdminChartsProgramTraineeProfiles();
    revalidateAdminChartsProgramActiveRelationships();
    revalidateAdminChartsProgramConcludedRelationships();
    revalidateAdminChartsGoals();
    revalidateAdminChartsSessions();
    revalidateAdminChartsSkillsSought();
    revalidateAdminChartsSkillsOffered();
    revalidateAdminChartsProgramPostRelationshipSurveys();
    revalidateAdminChartsProgramPostSessionSurveys();
    getUpdatedAt();
  }

  const adminKPIProgram = adminKPIsProgram?.at(0);
  const memberCount = adminKPIProgram?.memberCount;
  const guideMemberships = adminKPIProgram?.guideMemberships;
  const traineeMemberships = adminKPIProgram?.traineeMemberships;
  const relationshipCount = adminKPIProgram?.relationshipCount;
  const activeRelationshipCount = adminKPIProgram?.activeRelationshipCount;
  const concludedRelationshipCount =
    adminKPIProgram?.concludedRelationshipCount;
  const relationshipsWithMultipleSessions =
    adminKPIProgram?.relationshipsWithMultipleSessions;
  const sessionCount = adminKPIProgram?.sessionCount;
  const goalsCreatedCount = adminKPIProgram?.goalsCreatedCount;

  const adminKPIOrganization = adminKPIsOrganization?.at(0);
  const organizationMemberCount = adminKPIOrganization?.memberCount;

  const keyStats = [
    {
      label: 'Program members',
      toolTipMessage: 'The total number of members enrolled in this program.',
      value: memberCount,
      key: 'users',
      percentageNumber: (memberCount ?? 0) / (organizationMemberCount ?? 0),
      description: `${
        organizationMemberCount
          ? Math.round(((memberCount ?? 0) / organizationMemberCount) * 100)
          : 0
      }% of total organization users`,
    },
    {
      label: `${guideOrganizationLabel} memberships`,
      toolTipMessage: `The total number of program members with a ${guideOrganizationLabel} membership.`,
      value: guideMemberships,
      key: 'guideMemberships',
      percentageNumber: (guideMemberships ?? 0) / (memberCount ?? 0),
      description: `${
        memberCount
          ? Math.round(((guideMemberships ?? 0) / memberCount) * 100)
          : 0
      }% of total program members`,
      ref: 'guideTable',
    },
    {
      label: `${traineeOrganizationLabel} memberships`,
      toolTipMessage: `The total number of program members with a ${traineeOrganizationLabel} membership.`,
      value: traineeMemberships,
      key: 'traineeMemberships',
      percentageNumber: (traineeMemberships ?? 0) / (memberCount ?? 0),
      description: `${
        memberCount
          ? Math.round(((traineeMemberships ?? 0) / memberCount) * 100)
          : 0
      }% of total program members`,
      ref: 'traineeTable',
    },
    {
      label: 'Relationships created',
      toolTipMessage:
        'The total number of relationships created in this program.',
      value: relationshipCount,
      key: 'relationships',
      description: 'relationships',
    },

    {
      label: 'Active relationships',
      value: activeRelationshipCount,
      key: 'activeRelationships',
      toolTipMessage:
        'The total number of active relationships in this program.',
      percentageNumber:
        (activeRelationshipCount ?? 0) / (relationshipCount ?? 0),
      description: `${
        relationshipCount
          ? Math.round(
              ((activeRelationshipCount ?? 0) / relationshipCount) * 100,
            )
          : 0
      }% of total relationships`,
      ref: 'activeRelationships',
    },
    {
      label: 'Concluded relationships',
      value: concludedRelationshipCount,
      key: 'concludedRelationships',
      toolTipMessage:
        'The total number of concluded relationships in this program.',
      percentageNumber:
        (concludedRelationshipCount ?? 0) / (relationshipCount ?? 0),
      description: `${
        relationshipCount
          ? Math.round(
              ((concludedRelationshipCount ?? 0) / relationshipCount) * 100,
            )
          : 0
      }% of total relationships`,
      ref: 'concludedRelationships',
    },
    {
      label: 'Sessions booked/logged',
      toolTipMessage:
        'The total number of sessions booked/logged in this program.',
      value: sessionCount,
      key: 'sessions',
      description: 'sessions',
      ref: 'sessions',
    },
    {
      label: 'Multi-session relationships',
      toolTipMessage:
        'The total number of relationships with more than one session booked/logged.',
      value: relationshipsWithMultipleSessions,
      key: 'RelationshipsCount',
      percentageNumber:
        (relationshipsWithMultipleSessions ?? 0) / (relationshipCount ?? 0),
      description: `${
        relationshipCount
          ? Math.round(
              ((relationshipsWithMultipleSessions ?? 0) / relationshipCount) *
                100,
            )
          : 0
      }% of total relationships`,
    },
    {
      label: 'Goals created',
      toolTipMessage: 'The total number of goals created in this program.',
      value: goalsCreatedCount,
      key: 'goalsCreated',
      description: 'goalsCreated',
    },
  ];

  const today = new Date().toLocaleDateString();

  const guideProgramFieldsColumns = getSanityProgramFieldColumns<
    IChartsProgramTraineeProfile | IChartsProgramGuideProfile
  >({
    fields: sanityProgramFields.filter(
      (field) => field.audience !== 'trainee' && field.type !== 'free-text',
    ),
    customFieldId: (id) => `programFields.${id}`,
    getColumnOptions: (id) => ({
      minWidth: 130,
      hideable: true,
      valueGetter: ({ row }) => {
        const fieldSlug: keyof (
          | IChartsProgramTraineeProfile
          | IChartsProgramGuideProfile
        )['programFields'] = id;
        const programFields = row.programFields;

        return programFields[fieldSlug];
      },
    }),
  });

  const guideProfileColumns: GridColDef<IChartsProgramGuideProfile>[] = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="text"
          size="small"
          sx={{
            maxWidth: '100%',
          }}
          color="primary"
          onClick={() => {
            navigate(`/users/${params.row.profileId}`);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    { field: 'firstName', headerName: 'First name', minWidth: 100, flex: 0.5 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100, flex: 0.5 },

    ...getCountryColumn<IChartsProgramGuideProfile>({
      field: 'country',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'joinedProgramOn',
      headerName: 'Joined program on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    {
      ...PROFILE_STATUS_COLUMN,
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
      type: 'number',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
      type: 'number',
    },
    {
      field: 'totalSessions',
      headerName: 'Total sessions',
      type: 'number',
    },
    ...guideProgramFieldsColumns,
    ...sanityOrganizationFieldColumns,
  ];

  const traineeProgramFieldsColumns = getSanityProgramFieldColumns<
    IChartsProgramTraineeProfile | IChartsProgramGuideProfile
  >({
    fields: sanityProgramFields.filter(
      (field) => field.audience !== 'guide' && field.type !== 'free-text',
    ),
    customFieldId: (id) => `programFields.${id}`,
    getColumnOptions: (id) => ({
      minWidth: 130,
      hideable: true,
      valueGetter: ({ row }) => {
        const fieldSlug: keyof (
          | IChartsProgramTraineeProfile
          | IChartsProgramGuideProfile
        )['programFields'] = id;
        const programFields = row.programFields;

        return programFields[fieldSlug];
      },
    }),
  });

  const traineeProfileColumns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="text"
          size="small"
          sx={{
            maxWidth: '100%',
          }}
          color="primary"
          onClick={() => {
            navigate(`/users/${params.row.profileId}`);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    { field: 'firstName', headerName: 'First name', minWidth: 100, flex: 0.4 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100, flex: 0.4 },
    ...getCountryColumn({
      field: 'country',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'joinedProgramOn',
      headerName: 'Joined program on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      flex: 0.5,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
      type: 'number',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
      type: 'number',
    },
    {
      field: 'totalSessions',
      headerName: 'Total sessions',
      type: 'number',
    },
    ...traineeProgramFieldsColumns,
    ...sanityOrganizationFieldColumns,
  ];

  const getGuideSanityCustomFields =
    getSanityProfileFieldColumns<IChartsProgramActiveRelationship>({
      fields: sanityOrganizationFields,
      customFieldId: (id) => `guideCustomFields.${id}`,
      customHeader: (label, id) => `${guideOrganizationLabel} ${label ?? id}`,
      getColumnOptions: (id) => ({
        minWidth: 130,
        hideable: true,
        valueGetter: ({ row }) => {
          const fieldSlug: keyof IChartsProgramActiveRelationship['guideCustomFields'] =
            id;
          const customFields = row.guideCustomFields;

          return customFields[fieldSlug];
        },
      }),
    });

  const getTraineeSanityCustomFields =
    getSanityProfileFieldColumns<IChartsProgramActiveRelationship>({
      fields: sanityOrganizationFields,
      customFieldId: (id) => `traineeCustomFields.${id}`,
      customHeader: (label, id) => `${traineeOrganizationLabel} ${label ?? id}`,
      getColumnOptions: (id) => ({
        minWidth: 130,
        hideable: true,
        valueGetter: ({ row }) => {
          const fieldSlug: keyof IChartsProgramActiveRelationship['traineeCustomFields'] =
            id;
          const customFields = row.traineeCustomFields;

          return customFields[fieldSlug];
        },
      }),
    });

  const activeRelationshipColumns: GridColDef<IChartsProgramActiveRelationship>[] =
    [
      {
        field: 'createdAt',
        headerName: 'Created on',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: ({ value }) =>
          value && format(new Date(value), 'dd/MM/yyyy'),
        minWidth: 100,
        filterOperators: [getCustomGridDateFilterOperator({})],
      },
      {
        field: 'guideEmail',
        headerName: `${guideOrganizationLabel} email`,
        minWidth: 200,
        flex: 0.5,
      },
      {
        field: 'guideFirstName',
        headerName: `${guideOrganizationLabel} first name`,
        minWidth: 130,
      },
      {
        field: 'guideLastName',
        headerName: `${guideOrganizationLabel} last name`,
        minWidth: 130,
      },
      ...getCountryColumn({
        field: 'guideCountry',
        enabled: isSanityOrganizationCountryEnabled,
        countryOptions: countryOptions,
      }),
      {
        field: 'guideJobRole',
        headerName: `${guideOrganizationLabel} job role`,
        minWidth: 130,
      },
      ...getGuideSanityCustomFields,
      {
        field: 'traineeEmail',
        headerName: `${traineeOrganizationLabel} email`,
        minWidth: 200,
        flex: 0.5,
      },
      {
        field: 'traineeFirstName',
        headerName: `${traineeOrganizationLabel} first name`,
        minWidth: 130,
      },
      {
        field: 'traineeLastName',
        headerName: `${traineeOrganizationLabel} last name`,
        minWidth: 130,
      },
      {
        field: 'traineeJobRole',
        headerName: `${traineeOrganizationLabel} job role`,
        minWidth: 130,
      },
      ...getTraineeSanityCustomFields,
      {
        field: 'sessionsHeld',
        headerName: 'Sessions held',
        type: 'number',
      },
      {
        field: 'goalsShared',
        headerName: 'Goals shared',
        type: 'number',
      },
    ];

  const sanitySkillsLabels = program?.registration?.skills?.options;

  const concludedRelationshipColumns: GridColDef[] = [
    {
      field: 'concludedAt',
      headerName: 'Concluded on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    {
      field: 'guideEmail',
      headerName: `${guideOrganizationLabel} email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'guideFirstName',
      headerName: `${guideOrganizationLabel} first name`,
      minWidth: 130,
    },
    {
      field: 'guideLastName',
      headerName: `${guideOrganizationLabel} last name`,
      minWidth: 130,
    },
    ...getCountryColumn({
      field: 'guideCountry',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'traineeEmail',
      headerName: `${traineeOrganizationLabel} email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'traineeFirstName',
      headerName: `${traineeOrganizationLabel} first name`,
      minWidth: 130,
    },
    {
      field: 'traineeLastName',
      headerName: `${traineeOrganizationLabel} last name`,
      minWidth: 130,
    },
    {
      field: 'sessionsHeld',
      headerName: 'Sessions held',
      type: 'number',
    },
    {
      field: 'goalsShared',
      headerName: 'Goals shared',
      type: 'number',
    },
  ];

  // surveysColumns

  const getSanitySessionSurveyFields =
    getSanitySurveyFieldColumns<IChartsProgramPostSessionSurveys>({
      fields: sanityPostSessionSurvey,
      surveyFieldId: (id) => `surveyAnswers.${id}`,
      customHeader: (audience, fieldLabel, id) =>
        `${audience ? `${fieldLabel}` ?? id : ''}`,
      getColumnOptions: (id) => ({
        minWidth: 130,
        hideable: true,
        valueGetter: ({ row }) => {
          const fieldSlug: keyof IChartsProgramPostSessionSurveys['surveyAnswers'] =
            id;
          const surveyAnswers = row.surveyAnswers;
          return surveyAnswers[fieldSlug];
        },
      }),
    });

  const sessionsSurveysColumns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
    },
    {
      field: 'firstName',
      headerName: `First Name`,
      minWidth: 200,
    },
    {
      field: 'lastName',
      headerName: `Last Name`,
      minWidth: 200,
    },
    {
      field: 'sessionCounterPartyEmail',
      headerName: `Email`,
      minWidth: 200,
    },
    {
      field: 'sessionCounterPartyFirstName',
      headerName: `First name`,
      minWidth: 130,
    },
    {
      field: 'sessionCounterPartyLastName',
      headerName: `Last name`,
      minWidth: 130,
    },
    {
      field: 'sessionEndTime',
      headerName: 'Session end',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    ...getSanitySessionSurveyFields,
  ];

  const getSanityRelationshipSurveyFields =
    getSanitySurveyFieldColumns<IChartsProgramPostRelationshipSurveys>({
      fields: sanityPostRelationshipSurvey,
      surveyFieldId: (id) => `surveyAnswers.${id}`,
      customHeader: (audience, id) => `${audience ?? id}`,
      getColumnOptions: (id) => ({
        minWidth: 130,
        hideable: true,
        valueGetter: ({ row }) => {
          const fieldSlug: keyof IChartsProgramPostRelationshipSurveys['surveyAnswers'] =
            id;
          const surveyAnswers = row.surveyAnswers;
          return surveyAnswers[fieldSlug];
        },
      }),
    });

  const relationshipsSurveysColumns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
    },
    {
      field: 'firstName',
      headerName: `First Name`,
      minWidth: 200,
    },
    {
      field: 'lastName',
      headerName: `Last Name`,
      minWidth: 200,
    },
    {
      field: 'relationshipCounterPartyEmail',
      headerName: `Email`,
      minWidth: 200,
    },
    {
      field: 'relationshipCounterPartyFirstName',
      headerName: `First name`,
      minWidth: 130,
    },
    {
      field: 'relationshipCounterPartyLastName',
      headerName: `Last name`,
      minWidth: 130,
    },
    {
      field: 'relationshipConcludedAt',
      headerName: 'Relationship Concluded At',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    ...getSanityRelationshipSurveyFields,
  ];

  // Goals table

  const goalsColumns = getGoalsColumns({
    traineeLabel: traineeOrganizationLabel,
    guideLabel: guideOrganizationLabel,
    isSanityOrganizationCountryEnabled: isSanityOrganizationCountryEnabled,
    countryOptions: countryOptions,
  });

  const sessionsColumns: GridColDef[] = [
    {
      field: 'sessionStart',
      headerName: 'Sessions start',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    {
      field: 'sessionEnd',
      headerName: 'Session end',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    { field: 'name', headerName: 'Name', minWidth: 200 },
    {
      field: 'duration',
      headerName: 'Duration',
      valueFormatter: ({ value }) => value && `${value} minutes`,
    },
    {
      field: 'guideFirstName',
      headerName: `${guideOrganizationLabel} First Name`,
      minWidth: 130,
    },
    {
      field: 'guideLastName',
      headerName: `${guideOrganizationLabel} Last Name`,
      minWidth: 130,
    },
    ...getCountryColumn({
      field: 'guideCountry',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'guideEmail',
      headerName: `${guideOrganizationLabel} Email`,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'traineeFirstName',
      headerName: `${traineeOrganizationLabel} First Name`,
      minWidth: 130,
    },
    {
      field: 'traineeLastName',
      headerName: `${traineeOrganizationLabel} Last Name`,
      minWidth: 130,
    },
    {
      field: 'traineeEmail',
      headerName: `${traineeOrganizationLabel} Email`,
      minWidth: 200,
      flex: 0.5,
    },
    { field: 'location', headerName: 'Location' },
    { field: 'videoConferencing', headerName: 'Communication tool' },
  ];

  const relationshipsPieChartData = [
    {
      label: 'Active',
      value: activeRelationshipCount ?? 0,
      color: palette.success.main,
    },
    {
      label: 'Concluded',
      value: concludedRelationshipCount ?? 0,
      color: '#C6D9D5',
    },
  ];

  const membershipsPieChartData = [
    {
      label: `${guideOrganizationLabelPluralized}`,
      value: guideMemberships ?? 0,
      color: palette.warning.main,
    },
    {
      label: `${traineeOrganizationLabelPluralized}`,
      value: traineeMemberships ?? 0,
      color: palette.warning.light,
    },
  ];

  const skillsColors = [
    { id: 0, color: '#008CF9' },
    { id: 1, color: '#F5363B' },
    { id: 2, color: '#FFBE4F' },
    { id: 3, color: '#00307A' },
    { id: 4, color: '#67CCFE' },
    { id: 5, color: '#00BB92' },
    { id: 6, color: '#3D3BC4' },
  ];

  function groupByCount(data: (IChartsSkillsSought | IChartsSkillsOffered)[]) {
    const topFiveSkills = data.sort((a, b) => b.count - a.count).slice(0, 5);
    const others = {
      fieldSlug: 'other',
      count: data.reduce(
        (sum: number, item) =>
          sum + (topFiveSkills.includes(item) ? 0 : item.count),
        0,
      ),
    };
    return [...topFiveSkills, others];
  }

  const skillsSoughtGroupedData = groupByCount([...adminChartsSkillsSought]);

  const skillsSoughtPieChartData = skillsSoughtGroupedData.map(
    (skill, index) => {
      function PieChartData() {
        if (index === 5) {
          return {
            label: 'Other',
            value: skill.count,
            color: skillsColors.find((color) => color.id === index)?.color,
          };
        } else {
          return {
            label: sanitySkillsLabels?.find(
              (sanitySkill) => sanitySkill.id.current === skill.fieldSlug,
            )?.label,
            value: skill.count,
            color: skillsColors.find((color) => color.id === index)?.color,
          };
        }
      }

      return PieChartData();
    },
  );

  const skillsOfferedGroupedData = groupByCount([...adminChartsSkillsOffered]);

  const skillsOfferedPieChartData = skillsOfferedGroupedData.map(
    (skill, index) => {
      function PieChartData() {
        if (index === 5) {
          return {
            label: 'Other',
            value: skill.count,
            color: skillsColors.find((color) => color.id === index)?.color,
          };
        } else {
          return {
            label: sanitySkillsLabels?.find(
              (sanitySkill) => sanitySkill.id.current === skill.fieldSlug,
            )?.label,
            value: skill.count,
            color: skillsColors.find((color) => color.id === index)?.color,
          };
        }
      }

      return PieChartData();
    },
  );

  const relationshipsTotal = relationshipsPieChartData
    .map((item) => item.value)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0);

  const getRelationshipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / (relationshipsTotal ?? 0);
    return `${(percent * 100).toFixed(0)}%`;
  };

  const membershipsTotal = membershipsPieChartData
    .map((item) => item.value)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0);

  const skillsSoughtTotal = skillsSoughtPieChartData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const skillsOfferedTotal = skillsOfferedPieChartData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getMembershipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / (membershipsTotal ?? 0);
    return `${(percent * 100).toFixed(0)}%`;
  };

  const getSkillsSoughtArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / skillsSoughtTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const getSkillsOfferedArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / skillsOfferedTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const handleCardClick = (cardName: string) => {
    if (tableRefs[cardName].current) {
      tableRefs[cardName]!.current!.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PageView
      title={`${programName}`}
      subtitles={[`${programTypeName}`]}
      headerSx={{
        alignItems: 'flex-start',
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? '24px' : 0,
        textWrap: 'nowrap',
      }}
      headerElement={
        <Stack
          direction={'column'}
          spacing={0}
          width={'100%'}
          alignItems={'flex-end'}
          flexWrap={isMobile ? 'wrap' : undefined}
          paddingRight={1}
        >
          <Button
            variant="textWithIcon"
            startIcon={<Refresh />}
            label="Refresh data"
            loading={isLoading || isValidating}
            onClick={refresh}
          />
          <Text
            text={getUpdatedAt()}
            variant="subtitle2"
            color={palette.paper.text.disabled}
            sx={{ textWrap: 'nowrap' }}
          />
        </Stack>
      }
      sx={{
        backgroundColor: palette.paper.background.primary,
        display: 'flex',
      }}
    >
      <Stack direction="row" width="100%" py={3} px={1}>
        <MetricsProgramIndividualFilterContainer
          countryOptions={countryOptions}
          countryEnabled={isSanityOrganizationCountryEnabled}
        />
      </Stack>
      <Stack direction="column" width="100%" gap={5}>
        <MuiGrid
          container
          direction="row"
          alignItems="stretch"
          spacing={1}
          rowGap={2}
        >
          {keyStats.map((stat) => (
            <MuiGrid
              key={stat.key}
              item
              xs={12}
              sm={6}
              md={4}
              xl={4}
              paddingRight={2}
            >
              <KeyStatCard
                label={stat.label}
                toolTipMessage={stat.toolTipMessage}
                value={stat.value}
                description={stat.description}
                percentageNumber={stat.percentageNumber}
                cardRef={
                  stat.ref ? () => handleCardClick(stat?.ref) : undefined
                }
              />
            </MuiGrid>
          ))}
        </MuiGrid>
        <MuiGrid
          container
          rowSpacing={2}
          gap={isMobile ? 2 : 0}
          direction="row"
          display={'flex'}
        >
          <PieChartCard
            title="Relationships"
            data={relationshipsPieChartData}
            arcLabel={getRelationshipsArcLabel}
            isLoading={
              isLoadingAdminKPIsProgram || adminKPIProgram === undefined
            }
            fileName={`${organizationSlug}-relationships-${today}`}
            sxProps={{ mr: 3 }}
          />
          <PieChartCard
            title="Memberships"
            data={membershipsPieChartData}
            arcLabel={getMembershipsArcLabel}
            isLoading={
              isLoadingAdminKPIsProgram || adminKPIProgram === undefined
            }
            fileName={`${organizationSlug}-memberships-${today}`}
          />
        </MuiGrid>
        <div ref={tableRefs['guideTable']}>
          <MongoMetricsContainer
            reportName="program-guide-profiles"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-${guideOrganizationLabel?.toLocaleLowerCase()}-profiles-${today}`}
            metricsTableProps={{
              ...programGuideModel,
              heading: `${guideOrganizationLabel} profiles`,
              rows: adminChartsProgramGuideProfiles,
              columns: guideProfileColumns,
              loading: isLoadingAdminChartsProgramGuideProfiles,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramGuideModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramGuideModel({ sortModel });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateProgramGuideModel({ paginationModel: model });
              },
            }}
          />
        </div>

        <div ref={tableRefs['traineeTable']}>
          <MongoMetricsContainer
            reportName="program-trainee-profiles"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-${traineeOrganizationLabel?.toLocaleLowerCase()}-profiles-${today}`}
            metricsTableProps={{
              ...programTraineeModel,
              heading: `${traineeOrganizationLabel} profiles`,
              rows: adminChartsProgramTraineeProfiles,
              columns: traineeProfileColumns,
              loading: isLoadingAdminChartsProgramTraineeProfiles,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramTraineeModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramTraineeModel({ sortModel });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateProgramTraineeModel({ paginationModel: model });
              },
            }}
          />
        </div>
        <div ref={tableRefs['activeRelationships']}>
          <MongoMetricsContainer
            reportName="program-active-relationships"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-active-relationships-${today}`}
            metricsTableProps={{
              ...programActiveRelationshipsModel,
              heading: 'Active relationships',
              rows: adminChartsProgramActiveRelationships,
              columns: activeRelationshipColumns,
              loading: isLoadingAdminChartsProgramActiveRelationships,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramActiveRelationshipsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramActiveRelationshipsModel({ sortModel });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateProgramActiveRelationshipsModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
        <div ref={tableRefs['concludedRelationships']}>
          <MongoMetricsContainer
            reportName="program-concluded-relationships"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-concluded-relationships-${today}`}
            metricsTableProps={{
              ...programConcludedRelationshipsModel,
              heading: 'Concluded relationships',
              rows: adminChartsProgramConcludedRelationships,
              columns: concludedRelationshipColumns,
              loading: isLoadingAdminChartsProgramConcludedRelationships,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramConcludedRelationshipsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramConcludedRelationshipsModel({ sortModel });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateProgramConcludedRelationshipsModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
        <div ref={tableRefs['sessions']}>
          <MongoMetricsContainer
            reportName="sessions"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-sessions-${today}`}
            metricsTableProps={{
              ...sessionsModel,
              heading: 'Sessions',
              rows: adminChartsSessions,
              columns: sessionsColumns,
              loading: isLoadingAdminChartsSessions,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateSessionsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateSessionsModel({ sortModel });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateSessionsModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
        <div ref={tableRefs['programGoalsTable']}>
          <MongoMetricsContainer
            {...programGoalsModel}
            reportPath="goals"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-goals-${today}`}
            metricsTableProps={{
              heading: `Goals shared`,
              rows: adminChartsGoals,
              columns: goalsColumns,
              loading: isLoadingAdminChartsGoals,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramGoalsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramGoalsModel({
                  sortModel,
                });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
            }}
          />
        </div>
        <MuiGrid
          container
          rowSpacing={2}
          gap={isMobile ? 2 : 0}
          direction="row"
          display={'flex'}
        >
          <PieChartCard
            title="Skills Sought"
            data={skillsSoughtPieChartData}
            arcLabel={getSkillsSoughtArcLabel}
            isLoading={
              isLoadingAdminChartsSkillsSought || adminKPIProgram === undefined
            }
            fileName={`${organizationSlug}-skills-sought-${today}`}
            sxProps={{ mr: 3 }}
          />
          <PieChartCard
            title="Skills Offered"
            data={skillsOfferedPieChartData}
            isLoading={
              isLoadingAdminChartsSkillsOffered || adminKPIProgram === undefined
            }
            arcLabel={getSkillsOfferedArcLabel}
            fileName={`${organizationSlug}-skills-offered-${today}`}
          />
        </MuiGrid>
        <Stack
          direction={isMobile || isLaptop ? 'column' : 'row'}
          justifyContent="space-between"
          display={'flex'}
          spacing={2}
        >
          <SkillsMetricsTableContainer
            programSlug={programSlug}
            sanitySkillsLabels={sanitySkillsLabels ?? []}
            traineeLabelPluralized={traineeOrganizationLabelPluralized}
            guideLabelPluralized={guideOrganizationLabelPluralized}
          />
        </Stack>
        <div ref={tableRefs['programRelationshipSurveysTable']}>
          <MongoMetricsContainer
            {...programSurveysPostRelationshipModel}
            reportName="program-post-relationship-surveys"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-post-relationship-surveys-${today}`}
            metricsTableProps={{
              heading: `Post Relationships Survey`,
              rows: adminChartsProgramPostRelationshipSurveys,
              columns: relationshipsSurveysColumns,
              loading: isLoadingAdminChartsProgramPostRelationshipSurveys,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramSurveysPostRelationshipModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramSurveysPostRelationshipModel({
                  sortModel,
                });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateProgramSurveysPostRelationshipModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
        <div ref={tableRefs['programSessionSurveysTable']}>
          <MongoMetricsContainer
            {...programSurveysPostSessionModel}
            reportName="program-post-session-surveys"
            programSlug={programSlug}
            filename={`${organizationSlug}-${programSlug}-post-session-surveys-${today}`}
            metricsTableProps={{
              heading: `Post Sessions Survey`,
              rows: adminChartsProgramPostSessionSurveys,
              columns: sessionsSurveysColumns,
              loading: isLoadingAdminChartsProgramPostSessionSurveys,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateProgramSurveysPostSessionModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateProgramSurveysPostSessionModel({
                  sortModel,
                });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateProgramSurveysPostSessionModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
      </Stack>
    </PageView>
  );
}

const PROFILE_STATUS_OPTIONS: IMultiSelectChipFilterOption[] = [
  {
    id: 'Published',
    label: 'Published',
    color: 'success',
  },
  {
    id: 'Unpublished',
    label: 'Unpublished',
    color: 'secondary',
  },
  {
    id: 'Awaiting Approval',
    label: 'Awaiting Approval',
    color: 'warning',
  },
  {
    id: 'Blocked',
    label: 'Blocked',
    color: 'info',
  },
];

export const PROFILE_STATUS_COLUMN: GridColDef = {
  field: 'status',
  minWidth: 150,
  headerName: 'Profile status',
  renderCell: (params) => {
    const getChipColor = () => {
      if (params.row.status === 'Published') return 'success';
      if (params.row.status === 'Unpublished') return 'secondary';
      if (params.row.status === 'Awaiting Approval') return 'warning';
      return 'info';
    };
    const color = getChipColor();
    return (
      <Chip
        label={firstLetterUppercase(params.row.status)}
        size="small"
        color={color}
      />
    );
  },
  filterOperators: [
    {
      label: 'select',
      value: 'includes',
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (
          !filterItem.field ||
          !filterItem.value ||
          !filterItem.operator ||
          !Array.isArray(filterItem.value)
        ) {
          return null;
        }
        return (params: GridCellParams): boolean => {
          return filterItem.value.includes(params.value);
        };
      },
      InputComponent: CustomAutocompleteFilter,
      InputComponentProps: {
        options: PROFILE_STATUS_OPTIONS,
        label: 'Status Tags',
      },
      getValueAsString: (value) => {
        if (Array.isArray(value)) {
          return value.map((item) => item.id);
        }
        return value?.id;
      },
    },
  ],
};
